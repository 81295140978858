export const CURRENT_CONFIG = {

  // license
  appId: '147307', // You need to go to the development website to apply.
  appKey: '8c21d83607c5cb7aa8621c545dc549a', // You need to go to the development website to apply.
  appLicense: 'FnOFoBuEmTIn3IoHu8ZIv2p6MhfUfmdDA4rod2WCElGQkkrwdQETJ9khwMu9LW3A7TSr5owjyR6hwPliYhIdoRdoN6Baikq3h6hLAbqGz6NMfKoLqm7Ow4HxIxdZ8i3Se1QoqWH1xjDgWwU22qRQuKuw52HoEj8YnmJU6quJEXs=', // You need to go to the development website to apply.

  // http
  baseURL: 'https://dji.api.kzzv.cn/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'ws://218.78.128.220:26789/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://rtsp.kzzv.cn/live/', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora shengwang.cn
  agoraAPPID: '33dee47a542c42e79dc96f777ba9a03f',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'live',

  // map
  // You can apply on the AMap website.
  amapKey: 'f528a9549ef7c0dc6fdfad1ae0ff5d62', // lixin

}
